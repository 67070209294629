// vendors
import React from 'react';
import PropTypes from 'prop-types';

export const LoadingContext = React.createContext(null);

export function useLoadingContext() {
  const context = React.useContext(LoadingContext);

  if (!context) {
    throw new Error('useLoadingContext must be used within a LoadingProvider');
  }

  return context;
}

export function LoadingContextProvider({ children }) {
  const [isLoading, setIsLoading] = React.useState(false);

  const value = React.useMemo(
    () => ({ isLoading, setIsLoading }),
    [isLoading, setIsLoading]
  );

  return (
    <LoadingContext.Provider value={value}>{children}</LoadingContext.Provider>
  );
}

LoadingContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
