/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';
import { LazyMotion, domAnimation } from 'framer-motion';

import French from './compiled-locales/fr.json';
import English from './compiled-locales/en.json';
import { LoadingContextProvider } from './src/contexts/Loading';

export default function WrapPageElement({
  element,
  props: {
    pageContext: { langKey },
  },
}) {
  const messages = langKey === 'en' ? English : French;

  return (
    <LoadingContextProvider>
      <LazyMotion features={domAnimation}>
        <IntlProvider locale={langKey} defaultLocale="fr" messages={messages}>
          {element}
        </IntlProvider>
      </LazyMotion>
    </LoadingContextProvider>
  );
}

WrapPageElement.propTypes = {
  element: PropTypes.node.isRequired,
  props: PropTypes.shape({
    pageContext: PropTypes.shape({
      langKey: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};
